<template>
  <div class="container">
    <div class="survey-header" :style="`background-image:url(${imagePath})`">
      <div class="layer">
        <div class="title-container">
          <div class="title-block" v-if="title">
            <span class="line-divider left"></span>
            <span class="title">{{ title }}</span>
            <span class="line-divider right"></span>
          </div>
        </div>
        <div class="description" v-html="description"></div>
      </div>
      <div class="description invisible" v-html="description"></div>
    </div>

    <div
      v-if="noSurvey"
      class="level1 no-padding"
      :style="`background-image:url(${logoBackground})`"
    >
      <div class="success box text-center">
        <ExclamationCircleFilled
          :style="{
            fontSize: '6em',
            color: '#e60505',
            marginTop: '30px',
            marginBottom: '20px',
          }"
        />

        <br />
        <h2>{{ $t("message.error") }}</h2>
        <p>{{ $t("message.channelNotFound") }}</p>
        <a-row>
          <a-col :xs="{ span: 18, offset: 3 }" :md="{ span: 10, offset: 7 }">
            <a-divider dashed> <img :src="flightsmodeImg" alt="" /> </a-divider>
          </a-col>
        </a-row>
      </div>
    </div>
    <div
      v-if="successSubmit"
      class="level1 no-padding"
      :style="`background-image:url(${logoBackground})`"
    >
      <div class="success box text-center">
        <CheckCircleFilled
          :style="{
            fontSize: '6em',
            color: '#03A400',
            marginTop: '30px',
            marginBottom: '20px',
          }"
        />
        <br />
        <h2>{{ $t("message.finished") }}</h2>
        <p>{{ $t("message.thank_for_your_time") }}</p>

        <a-row>
          <a-col :xs="{ span: 18, offset: 3 }" :md="{ span: 10, offset: 7 }">
            <a-divider dashed> <img :src="flightsmodeImg" alt="" /> </a-divider>
          </a-col>
        </a-row>
      </div>
    </div>

    <div v-if="!(successSubmit || noSurvey)" class="form-survey">
      <div v-if="frontendContent" class="level1">
        <div v-html="frontendContent"></div>
        <div class="text-center">
          <a-button
            type="primary"
            @click="surveyRender()"
            size="large"
            class="btn-survey"
            >开始问卷</a-button
          >
        </div>
      </div>

      <div v-else>
        <form class="survey-body" @submit.prevent="getFormValues">
          <div class="level1 submit">
            <div class="header text-center">
              <h4 class="">{{ $t("message.personal_info") }}</h4>
            </div>
            <div class="level2">
              <label ref="question_container_nickname"
                ><strong>{{ $t("message.fill_basic_info") }}</strong
                ><a-tooltip title="必需" color="#940606">
                  <span class="">✱</span>
                </a-tooltip></label
              >
              <br />
              <br />
              <a-row :gutter="[15, 15]">
                <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                  <a-input
                    v-model:value="surveyObjSubmit.nickname"
                    size="large"
                    :placeholder="$t('message.name')"
                    allow-clear
                    :style="{ borderRadius: 0 }"
                    id="nickname_input"
                  >
                    <template #prefix>
                      <user-outlined />
                    </template>
                    <template #suffix>
                      <a-tooltip :title="$t('message.pls_enter_name')">
                        <info-circle-outlined
                          style="color: rgba(0, 0, 0, 0.45)"
                        />
                      </a-tooltip>
                    </template>
                  </a-input>
                  <p v-if="nicknameError" class="red">{{ nicknameError }}</p>
                </a-col>
                <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                  <a-input
                    v-model:value="surveyObjSubmit.email"
                    size="large"
                    :placeholder="$t('message.email')"
                    allow-clear
                    :style="{ borderRadius: 0 }"
                    id="email_input"
                  >
                    <template #prefix>
                      <MailOutlined />
                    </template>
                    <template #suffix>
                      <a-tooltip :title="$t('message.pls_enter_email')">
                        <info-circle-outlined
                          style="color: rgba(0, 0, 0, 0.45)"
                        />
                      </a-tooltip>
                    </template>
                  </a-input>
                  <p v-if="emailError" class="red">{{ emailError }}</p>
                </a-col>
              </a-row>
              <br />
              <a-row :gutter="[15, 15]">
                <a-col v-if="survey_zh" :xs="{ span: 24 }" :md="{ span: 12 }">
                  <a-input
                    v-model:value="surveyObjSubmit.wechat_id"
                    size="large"
                    :placeholder="$t('message.wechat_id')"
                    allow-clear
                    :style="{ borderRadius: 0 }"
                    id="wechat_id_input"
                  >
                    <template #prefix>
                      <WechatOutlined />
                    </template>
                    <template #suffix>
                      <a-tooltip :title="$t('message.pls_enter_wechat')">
                        <info-circle-outlined
                          style="color: rgba(0, 0, 0, 0.45)"
                        />
                      </a-tooltip>
                    </template>
                  </a-input>
                  <p v-if="wechatError" class="red">{{ wechatError }}</p>
                </a-col>
                <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                  <a-input
                    v-model:value="surveyObjSubmit.phone_number"
                    size="large"
                    :placeholder="$t('message.phone')"
                    allow-clear
                    :style="{ borderRadius: 0 }"
                    id="phone_input"
                  >
                    <template #prefix>
                      <PhoneOutlined />
                    </template>
                    <template #suffix>
                      <a-tooltip :title="$t('message.pls_enter_phone')">
                        <info-circle-outlined
                          style="color: rgba(0, 0, 0, 0.45)"
                        />
                      </a-tooltip>
                    </template>
                  </a-input>
                  <p v-if="phoneError" class="red">{{ phoneError }}</p>
                </a-col>
              </a-row>
            </div>
          </div>
          <div
            v-for="group_quetion in group_question_items"
            :key="'group_' + group_quetion.id"
            class="level1"
            :style="`background-image:url(${logoBackground})`"
          >
            <div class="header">
              <h3>{{ group_quetion.group_title }}</h3>
              <h4>{{ group_quetion.group_text }}</h4>
            </div>
            <div
              v-for="question in sortArray(group_quetion.question_items)"
              :key="'item_' + question.id"
              class="question-area level2"
              :ref="'question_container_' + question.id"
            >
              <!-- TYPE SINGLE OR MULTIPLE {{group_quetion.group_text}}  -->
              <h4 class="question-header">
                {{ question.question_text }}
                {{
                  question.question_type === "OPEN"
                    ? "(" + $t("message.open_ans") + ")"
                    : question.question_type === "MULTIPLE"
                    ? "(" + $t("message.multiple_ans") + ")"
                    : "(" + $t("message.single_ans") + ")"
                }}
                <a-tooltip title="必需" color="#940606">
                  <span class="" v-if="question.is_required == 'Y'">✱</span>
                </a-tooltip>
              </h4>

              <div class="level3 ">
                <div v-if="question.question_type === 'MULTIPLE'">
                  <a-checkbox-group
                    v-model:value="answers[`question${question.id}`]"
                  >
                    <a-col
                      v-for="item in sortArray(question.question_options)"
                      :key="'item_' + item.id"
                      :xs="{ span: 24 }"
                      :md="{ span: 20 }"
                    >
                      <template v-if="item.option_type == 'VALUE'">
                        <a-checkbox
                          @click="removeRedClass(question.id)"
                          :value="item.id"
                          :id="'answer_' + item.id"
                          :style="{ marginTop: '5px' }"
                        >
                        {{ item.option_text }}
                        </a-checkbox>
                      </template>
                      <template v-else>
                        <a-col :xs="{ span: 24 }" :md="{ span: 20 }">
                          <a-checkbox
                            @click="removeRedClass(question.id)"
                            :value="item.id"
                            :id="'answer_' + item.id"
                            :style="{ marginTop: '5px' }"
                          >
                          {{ item.option_text }}
                          </a-checkbox>
                        </a-col>
                        <a-row>
                          <a-col :xs="{ span: 24 }" :md="{ span: 20 }">
                            <a-textarea
                              v-model:value="
                                answers[`question${question.id}text`]
                              "
                              :autoSize="{ minRows: 3, maxRows: 6 }"
                              @click="removeRedClass(question.id)"
                              @blur="handleBlur"
                              :disabled="
                                !answers[`question${question.id}`].includes(
                                  item.id
                                )
                              "
                              :name="`question${question.id}`"
                              :id="'answer_' + item.id"
                              :style="{
                                backgroundColor: 'transparent',
                                marginTop: '5px',
                                width: '100%',
                              }"
                              allow-clear
                            />
                          </a-col>
                        </a-row>
                      </template>
                    </a-col>
                  </a-checkbox-group>
                </div>
                <div v-else-if="question.question_type === 'SINGLE'">
                  <div
                    class="block"
                    v-for="item in question.question_options"
                    :key="'item_' + item.id"
                  >
                    <a-radio-group
                      :style="{ display: 'block', width: '100%' }"
                      v-model:value="answers[`question${question.id}`]"
                    >
                      <template v-if="item.option_type == 'VALUE'">
                        <a-radio
                          @click="removeRedClass(question.id)"
                          :name="`question${question.id}[]`"
                          :value="item.id"
                          :id="'answer_' + item.id"
                          :style="{ marginTop: '5px' }"
                          >{{ item.option_text }}</a-radio
                        >
                      </template>
                      <template v-else>
                        <a-radio
                          @click="removeRedClass(question.id)"
                          :name="`question${question.id}[]`"
                          :value="item.id"
                          :id="'answer_' + item.id"
                          :style="{ marginTop: '5px' }"
                          >{{ item.option_text }}</a-radio
                        >
                        {{ item.option_text }}
                        <a-row>
                          <a-col :xs="{ span: 24 }" :md="{ span: 20 }">
                            <a-textarea
                              v-model:value="
                                answers[`question${question.id}text`]
                              "
                              :autoSize="{ minRows: 3, maxRows: 6 }"
                              @click="removeRedClass(question.id)"
                              @blur="handleBlur"
                              :disabled="
                                answers[`question${question.id}`] !== item.id
                              "
                              :name="`question${question.id}`"
                              :id="'text_answer_' + item.id"
                              :style="{
                                backgroundColor: 'transparent',
                                marginTop: '5px',
                                width: '100%',
                              }"
                              allow-clear
                            />
                          </a-col>
                        </a-row>
                      </template>
                    </a-radio-group>
                  </div>
                </div>
                <div v-else>
                  <a-row>
                    <a-col :xs="{ span: 24 }" :md="{ span: 20 }">
                      <a-textarea
                        v-model:value="answers[`question${question.id}text`]"
                        :autoSize="{ minRows: 3, maxRows: 6 }"
                        @click="removeRedClass(question.id)"
                        @blur="handleBlur"
                        :name="`question${question.id}`"
                        :id="'answer_' + question.id"
                        :style="{
                          backgroundColor: 'transparent',
                          marginTop: '5px',
                          width: '100%',
                        }"
                        allow-clear
                      />
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
          </div>

          <div v-if="loading" class="loading">
            <div class="fixed-middle">
              <a-spin size="large" />
            </div>
          </div>
          <div class="level1 submit">
            <div class="level2">
              <div class="submit-button text-center">
                <a-button type="primary" size="large" html-type="submit">
                  {{ $t("message.submit") }}
                </a-button>
              </div>
            </div>
          </div>
        </form>
        <div class="survey-footer text-center">
          <div class="" v-html="end_text"></div>
        </div>
      </div>
    </div>
    <!-- END OF SURVEY FORM -->

    <footer>
      <div class="text-center">
        <a-row justify="center" align="middle">
          <a-col
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 24, offset: 0 }"
            :md="{ span: 22, offset: 2 }"
          >
            <img :src="footerLogo" alt="Logo Footer" />
            <a-divider
              class="footer-divider"
              type="vertical"
              width="2px"
              style="
                height: 30px;
                background-color: #353333;
                vertical-align: baseline;
              "
            />
            <span class="copyright">
              UNITED MASS TOURISM LLC.
              <br />
              All right reserved.
              <br />
            </span>
          </a-col>
          <a-col v-if="survey_zh" :span="24">
            <a class="link" href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备18135282号-1</a
            >
          </a-col>
        </a-row>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { Button, notification, Modal } from "ant-design-vue";
import imagePath from "@/assets/banner_01.jpg"; // Logo Assets
import logoBackground from "@/assets/logo-inTrip.png"; // Logo Assets
import footerLogo from "@/assets/footerlogo_en.png"; // Logo Assets
import flightsmodeImg from "@/assets/flightsmode.svg"; // Logo Assets

import {
  UserOutlined,
  InfoCircleOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  MailOutlined,
  WechatOutlined,
  PhoneOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "SurveyForm",
  props: {
    surveyId: String,
    userId: String,
    surveyChannel: String,
  },
  components: {
    AButton: Button,
    InfoCircleOutlined,
    UserOutlined,
    CheckCircleFilled,
    ExclamationCircleFilled,
    MailOutlined,
    WechatOutlined,
    PhoneOutlined,
  },
  data() {
    return {
      loading: true,
      frontendContent: null,
      answers: {},
      title: null,
      description: null,
      end_text: null,
      group_question_items: [],
      submitted: false,
      surveyData: null,
      list_question_answer: [],
      all_question_options: [],
      missingValidation: [],
      surveyObjSubmit: {
        user_id: null,
        survey_id: null,
        survey_channel: null,
        others: null,
        nickname: null,
        email: null,
        phone_number: null,
        wechat_id: null,
        list_question_answer: [],
      },
      imagePath,
      logoBackground,
      footerLogo,
      flightsmodeImg,
      successSubmit: false,
      noSurvey: false,
      emailError: "",
      nicknameError: "",
      wechatError: "",
      phoneError: "",
      survey_zh: 0,
    };
  },
  methods: {
    detectLanguage(title) {
      // Regular expression for detecting English characters
      const englishRegex = /^[A-Za-z0-9\s]*$/;

      if (! englishRegex.test(title)) {
        this.survey_zh = 1;
        this.$i18n.locale = "zh";
      }
    },
    handleBlur(e) {
      const target = e.currentTarget || e.target;
      if (target && target.value) {
        target.classList.remove("redline");
      } else {
        const { top } = target.getBoundingClientRect();
        target.classList.add("redline");
        window.scrollTo({
          top: window.scrollY + top - 30,
          behavior: "smooth",
        });
      }
    },
    submitSurvey() {
      this.submitted = true;
    },
    sortArray(objArray) {
      if (objArray) objArray.sort((a, b) => a.sort - b.sort);
      return objArray;
    },
    sortBySortAttribute() {
      this.group_question_items.sort((a, b) => a.sort - b.sort);
    },
    removeRedClass(id) {
      this.$refs[`question_container_${id}`][0].classList.remove("red");
    },
    getOptTextById(id) {
      let opt = this.all_question_options.filter((opt) => opt.id === id);
      let optQs = opt[0];
      if (optQs.option_type === "INPUT") {
        if (
          this.answers[`question${optQs.question_id}text`] &&
          this.answers[`question${optQs.question_id}text`].trim()
        )
          return this.answers[`question${optQs.question_id}text`];
        else this.missingValidation.push(optQs.question_id);
      }
      return optQs.option_text;
    },
    getJsonByMultipleIds(ids) {
      let json = [];

      ids.filter((id) => {
        json.push({ id: id, value: this.getOptTextById(id) });
      });
      return json;
    },

    validateEmail() {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.surveyObjSubmit.email) {
        this.emailError = this.$t("message.email_error");
      } else if (!emailRegex.test(this.surveyObjSubmit.email)) {
        this.emailError = this.$t("message.email_error");
      } else {
        this.emailError = null;
        this.$refs[`question_container_nickname`].classList.remove("red");
      }
    },

    surveyRender() {
      this.frontendContent = null;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 700);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (this.surveyData.is_submit) {
        Modal.success({
          title: this.$t(
            "message.you_have_already_participated_do_not_do_it_again"
          ),
          centered: true,
          okText: this.$t("message.okay"),
          onOk: () => {
            this.successSubmit = true;
          },
        });
      }
      this.title = this.surveyData.title;
      document.title = this.title;
      this.description = this.surveyData.description;
      this.end_text = this.surveyData.end_text;

      this.group_question_items = this.sortArray(
        this.surveyData.group_question_items
      );

      this.group_question_items.filter((group_question) => {
        if (group_question.question_items)
          group_question.question_items.filter((question) => {
            if (question.question_options)
              this.all_question_options = this.all_question_options.concat(
                question.question_options
              );
            if (question.question_type === "SINGLE") {
              this.answers[`question${question.id}`] = 0;
              this.list_question_answer.push({
                question_id: question.id,
                single_answer_id: 0,
                answer: "",
                is_required: question.is_required,
                type: "SINGLE",
              });
            } else if (question.question_type === "OPEN") {
              this.answers[`question${question.id}`] = 0;
              this.list_question_answer.push({
                question_id: question.id,
                answer: "",
                is_required: question.is_required,
                type: "OPEN",
              });
            } else {
              this.answers[`question${question.id}`] = [];
              this.list_question_answer.push({
                question_id: question.id,
                multiple_answer_ids: [],
                answer: "",
                is_required: question.is_required,
                type: "MULTIPLE",
              });
            }
          });
      });

      this.surveyObjSubmit.user_id = this.userId;
      this.surveyObjSubmit.survey_id = this.surveyId;
      this.surveyObjSubmit.survey_channel = this.surveyChannel;
    },

    getFormValues() {
      if (
        !(this.surveyObjSubmit.nickname && this.surveyObjSubmit.nickname.trim())
      ) {
        this.nicknameError = this.$t("message.please_enter_name");
      } else {
        this.nicknameError = null;
      }

      if (
        !(
          this.surveyObjSubmit.phone_number &&
          this.surveyObjSubmit.phone_number.trim()
        )
      ) {
        this.phoneError = this.$t("message.please_enter_phone");
      } else {
        this.phoneError = null;
      }

      if (
        !(
          this.surveyObjSubmit.wechat_id &&
          this.surveyObjSubmit.wechat_id.trim()
        )
      ) {
        this.wechatError = this.$t("message.please_enter_wechate");
      } else {
        this.wechatError = null;
      }

      this.validateEmail();

      if (!this.survey_zh) this.wechatError = null; // Skip We Chat in Survey English.

      if (
        this.wechatError ||
        this.phoneError ||
        this.nicknameError ||
        this.emailError
      ) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        notification["error"]({
          message: this.$t("message.please_fill_the_required"),
        });
        this.$refs[`question_container_nickname`].classList.add("red");
        return false;
      }

      this.$refs[`question_container_nickname`].classList.remove("red");
      this.missingValidation = [];
      this.list_question_answer.filter((question_answer) => {
        let is_require = question_answer.is_required == "Y";
        if (question_answer.type === "SINGLE") {
          // VALIDATION CHECK
          if (
            is_require &&
            this.answers[`question${question_answer.question_id}`] === 0
          ) {
            this.missingValidation.push(question_answer.question_id);
          }

          let id = this.answers[`question${question_answer.question_id}`];
          if (id > 0) {
            question_answer.single_answer_id = id;
            question_answer.answer = this.getOptTextById(id);
            question_answer.single_answer_json = {
              id: id,
              value: this.getOptTextById(id),
            };
          }
        } else if (question_answer.type === "OPEN") {
          if (
            this.answers[`question${question_answer.question_id}text`] &&
            this.answers[`question${question_answer.question_id}text`].trim()
          ) {
            question_answer.answer =
              this.answers[`question${question_answer.question_id}text`];
          } else {
            this.missingValidation.push(question_answer.question_id);
          }
        } else {
          // VALIDATION CHECK
          if (
            is_require &&
            this.answers[`question${question_answer.question_id}`].length == 0
          ) {
            this.missingValidation.push(question_answer.question_id);
          }

          if (
            this.answers[`question${question_answer.question_id}`].length > 0
          ) {
            // if (this.answers[`question${question_answer.question_id}text`])
            //   this.answers[`question${question_answer.question_id}`].push(question_answer.question_id);

            question_answer.answer =
              this.answers[`question${question_answer.question_id}text`];
            question_answer.multiple_answer_ids =
              this.answers[`question${question_answer.question_id}`];
            question_answer.multiple_answer_json = this.getJsonByMultipleIds(
              this.answers[`question${question_answer.question_id}`]
            );
          }
        }
      });

      if (this.missingValidation.length > 0) {
        this.missingValidation.filter((question_id) => {
          if (this.$refs[`question_container_${question_id}`][0]) {
            this.$refs[`question_container_${question_id}`][0].classList.add(
              "red"
            );
          }
        });

        notification["error"]({
          message: this.$t("message.please_answer_all_question"),
        });

        this.missingValidation[0] &&
          this.$refs[
            `question_container_${this.missingValidation[0]}`
          ][0].scrollIntoView({ behavior: "smooth", block: "center" });
        return false;
      }

      this.surveyObjSubmit.list_question_answer = this.list_question_answer;

      this.loading = true;
      axios
        .post(`${this.$getEndpoint()}/api/survey/submit`, this.surveyObjSubmit)
        .then((response) => {
          if (response) console.log(response);
          this.successSubmit = true;
        })
        .catch((error) => {
          let errorResponse = error.response ? error.response.data : error;
          if (errorResponse.message) {
            notification["error"]({
              message: errorResponse.message,
            });
          } else {
            notification["error"]({
              message: this.$t("message.channelNotFound"),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    // Make an API request when the component is created

    if (this.surveyId && this.surveyChannel) {
      axios
        .post(`${this.$getEndpoint()}/api/survey/detail`, {
          survey_id: this.surveyId,
          user_id: this.userId,
          survey_channel: this.surveyChannel,
        })
        .then((response) => {
          this.surveyData = response.data;
          this.detectLanguage(this.surveyData.title);
          if (this.surveyData.false && this.surveyData.front_content) {
            //  Disabled Frontnd Data
            this.frontendContent = this.surveyData.front_content;
            if (this.surveyData.is_submit) {
              Modal.success({
                title: "您已参与过问卷调查，请勿重复参与！",
                centered: true,
                okText: "好的",
                onOk: () => {
                  this.successSubmit = true;
                },
              });

              this.title = this.surveyData.title;
              document.title = this.title;
              this.description = this.surveyData.description;
              this.end_text = this.surveyData.end_text;
            }
          } else {
            this.surveyRender();
          }
        })
        .catch((error) => {
          this.noSurvey = true;
          if (error.message) {
            // this.$router.push("/survey-not-found", { title: error.message });
            notification["error"]({
              message: this.$t("message.channelNotFound"),
            });
          } else {
            notification["error"]({
              message: this.$t("message.channelNotFound"),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.noSurvey = true;
      notification["error"]({
        message: this.$t("message.channelNotFound"),
      });
      this.loading = false;
    }
  },
};
</script>

<style>

:where(.css-dev-only-do-not-override-185kyl0).ant-checkbox-group, 
:where(.css-1hsjdkk).ant-checkbox-group {
  white-space: normal;
}

:where(.css-1hsjdkk).ant-checkbox, 
:where(.css-1hsjdkk).ant-radio-wrapper .ant-radio,
:where(.css-dev-only-do-not-override-185kyl0).ant-checkbox, 
:where(.css-dev-only-do-not-override-185kyl0).ant-radio-wrapper .ant-radio  {
  align-self: flex-start;
  margin-top: 3px;
}

#app {
  /* background: #a7d1f35e; */
  background: #1f1f1ff0;
  line-height: 1.5;
}

.invisible {
  visibility: hidden;
  margin-bottom: 25px;
}
.absolute {
  position: absolute;
}

.right {
  right: 15px;
}

.success.box {
  padding: 30px;
}

.float-right {
  float: right;
}

.text-right {
  text-align: right;
}
.text-big {
  font-size: 20px;
}
.survey-header {
  /* position: fixed; */
  width: 100%;
  top: 0;
  left: 0;
  margin-top: -15px;
  position: relative;
  background-position: top;
  background-size: cover;
  background-color: #3f3f3f5f;
  padding: 30px 15px;
  color: white;
  min-height: 220px;
}

.survey-header .title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 2.5em;
  font-weight: 400;
}
.survey-header .description {
  padding: 30px 30px 0;
  font-size: 14px;
  text-align: justify;
}
:where(.css-185kyl0).ant-divider-horizontal.ant-divider-with-text::before,
:where(.css-185kyl0).ant-divider-horizontal.ant-divider-with-text::after,
:where(
    .css-dev-only-do-not-override-185kyl0
  ).ant-divider-horizontal.ant-divider-with-text::before,
:where(
    .css-dev-only-do-not-override-185kyl0
  ).ant-divider-horizontal.ant-divider-with-text::after {
  border-block-start: 4px solid #4ebee2;
}
:where(
    .css-185kyl0
  ).ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
:where(
    .css-185kyl0
  ).ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after,
:where(
    .css-dev-only-do-not-override-185kyl0
  ).ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
:where(
    .css-dev-only-do-not-override-185kyl0
  ).ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dotted none none;
}

.survey-header .description p {
  margin-bottom: 0;
  font-size: 14px;
}

.loading {
  position: fixed;
  top: 0;
  z-index: 9999 !important;
  left: 0;
  right: 0;
  text-align: center;
  height: 100%;
  width: 100%;
  background: #00000044;
}
.loading .fixed-middle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.red {
  color: #940606;
}
.container {
  max-width: 980px;
  margin: auto;
  padding: 15px;
  /* border: 1px solid #7d7a7a91;
    border-radius: 5px;
    box-shadow: -10px 5px 10px #7d7a7a; */
}

.text-center {
  text-align: center;
}

textarea {
  border-radius: 0 !important;
  background-color: #ffffff7d !important;
}

textarea:focus {
  background-color: rgba(233, 249, 255, 0.599) !important;
}

.line-break {
  white-space: normal;
  width: 100%;
}
.level1 {
  padding: 20px;
  background-color: #edefff;
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 20px solid #ffffff;
}

.level1 .header {
  background: linear-gradient(
    90.26deg,
    #3f2d9a -0.07%,
    #002da1 -0.07%,
    #563ee8 65.24%,
    #2c72ff 102.71%
  );
  color: #ffffff;
  padding: 10px 15px;
  position: relative;
  margin-bottom: 20px;
}

.level1 .header h4 {
  position: absolute;
  right: 0;
  left: 0;
  margin-bottom: 0;
  display: inline-block;
  text-align: center;
}

.level1.submit .header h4 {
  position: relative;
}

.level1 h3 {
  display: inline-block;
  margin-bottom: 0;
}

.level2 {
  padding-left: 15px;
  margin-bottom: 25px;
}

.level1.submit .level2 {
  margin: 0 20px;
}

.level1 .level2:last-child {
  margin-bottom: 0;
}

.level2 h4 {
  margin-bottom: 5px;
}
.level3 {
  padding-left: 15px;
}
button {
  border-radius: 0;
}

/* .level3 span {
    margin-top: 15px;
  } */

/* .level3 textarea {
    display: block;
    width: 80%;
  } */
.red textarea {
  border: 1px solid #940606 !important;
}

.no-padding {
  padding: 0;
  border-top: 0;
}

.survey-footer {
  background: white;
  padding: 20px;
  background-color: #edefff;
}
footer {
  background: white;
  padding: 20px;
  text-align: center;
  box-shadow: inset 0px 0px 5px #817b7b;
}

footer img {
  height: 40px;
  margin-right: 10px;
  vertical-align: top;
}

footer .copyright {
  display: inline-block;
  margin-left: 10px;
  text-align: left;
}
.survey-footer p {
  margin-bottom: 0;
}
.survey-footer h4 {
  margin: 0;
}

a.link {
  color: #4ebee2;
  text-decoration: none;
  margin-top: 10px;
}

.level1.submit input {
  border-radius: 0;
}
.layer {
  background: #0c0c0cb0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.line-divider {
  display: flex;
  align-items: center;
  margin: 25px 20px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-block-start: 0 rgba(5, 5, 5, 0.06);
  height: 1px;
  background-color: white;
  width: 70px;
}

.title-container {
  text-align: center;
  margin-top: 45px;
}

.line-divider.left {
  float: left;
}
.line-divider.right {
  float: right;
}
.title-block {
  display: inline-block;
}

button[type="submit"],
.btn-survey {
  background: linear-gradient(
    90.26deg,
    #3f2d9a -0.07%,
    #002da1 -0.07%,
    #563ee8 65.24%,
    #2c72ff 102.71%
  );
  padding-left: 50px !important;
  padding-right: 50px !important;
  text-align: center;
  margin-top: 15px;
}
input.redline,
textarea.redline {
  border-color: red;
}

input.redline:focus,
textarea.redline:focus {
  border-color: inherit;
}
:where(.css-185kyl0).ant-radio-wrapper .ant-radio,
:where(.css-dev-only-do-not-override-185kyl0).ant-radio-wrapper .ant-radio {
  /* align-self: auto; */
}


@media screen and (max-width: 480px) {
  .line-divider {
    margin: 15px;
    width: 40px;
  }
  .survey-header .title {
    font-size: 1.5em;
  }
  .survey-header .description {
    padding: 15px;
    font-size: 14px;
  }
  .level1 h3 {
    font-size: 1.1em;
    display: block;
  }

  .level1 .header h4 {
    position: relative;
    display: block;
  }

  footer .copyright {
    text-align: center;
    display: block;
    margin-top: 10px;
  }

  .level1.submit .level2 {
    padding-left: 0;
  }

  .footer-divider {
    display: none;
  }

  .container {
    margin: 0;
    padding: 0;
  }



 
}
</style>
